type KeyVariablesType = (t: any) => Record<string, string>;
type contextKeystype = Record<string, KeyVariablesType | KeyVariablesType[]>;

export const otherKeysVariables: KeyVariablesType = (t) => ({
  platform_naming: t('platform_naming'),
  company_naming: t('company_naming'),
});

export const expertKeysVariables: KeyVariablesType = (t) => ({
  expert_naming: t('expert_naming'),
  an_expert_naming: t('an_expert_naming'),
  the_expert_naming: t('the_expert_naming'),
  of_the_expert_naming: t('of_the_expert_naming'),
  of_expert_naming: t('of_expert_naming'),
  of_experts_naming: t('of_experts_naming'),
  this_expert_naming: t('this_expert_naming'),
  to_the_expert_naming: t('to_the_expert_naming'),
  expert_naming_cap: t('expert_naming_cap'),
  an_expert_naming_cap: t('an_expert_naming_cap'),
  the_expert_naming_cap: t('the_expert_naming_cap'),
  of_the_expert_naming_cap: t('of_the_expert_naming_cap'),
  of_expert_naming_cap: t('of_expert_naming_cap'),
  of_experts_naming_cap: t('of_experts_naming_cap'),
  this_expert_naming_cap: t('this_expert_naming_cap'),
  to_the_expert_naming_cap: t('to_the_expert_naming_cap'),
});

export const clientKeysVariables: KeyVariablesType = (t) => ({
  client_naming: t('client_naming'),
  an_client_naming: t('an_client_naming'),
  the_client_naming: t('the_client_naming'),
  of_the_client_naming: t('of_the_client_naming'),
  of_client_naming: t('of_client_naming'),
  of_clients_naming: t('of_clients_naming'),
  this_client_naming: t('this_client_naming'),
  to_the_client_naming: t('to_the_client_naming'),
  client_naming_cap: t('client_naming_cap'),
  an_client_naming_cap: t('an_client_naming_cap'),
  the_client_naming_cap: t('the_client_naming_cap'),
  of_the_client_naming_cap: t('of_the_client_naming_cap'),
  of_client_naming_cap: t('of_client_naming_cap'),
  of_clients_naming_cap: t('of_clients_naming_cap'),
  this_client_naming_cap: t('this_client_naming_cap'),
  to_the_client_naming_cap: t('to_the_client_naming_cap'),
});

export const missionKeysVariables: KeyVariablesType = (t) => ({
  mission_naming: t('mission_naming'),
  an_mission_naming: t('an_mission_naming'),
  the_mission_naming: t('the_mission_naming'),
  of_the_mission_naming: t('of_the_mission_naming'),
  of_mission_naming: t('of_mission_naming'),
  of_missions_naming: t('of_missions_naming'),
  this_mission_naming: t('this_mission_naming'),
  to_the_mission_naming: t('to_the_mission_naming'),
  mission_naming_cap: t('mission_naming_cap'),
  an_mission_naming_cap: t('an_mission_naming_cap'),
  the_mission_naming_cap: t('the_mission_naming_cap'),
  of_the_mission_naming_cap: t('of_the_mission_naming_cap'),
  of_mission_naming_cap: t('of_mission_naming_cap'),
  of_missions_naming_cap: t('of_missions_naming_cap'),
  this_mission_naming_cap: t('this_mission_naming_cap'),
  to_the_mission_naming_cap: t('to_the_mission_naming_cap'),
});

export const companyKeysVariables: KeyVariablesType = (t) => ({
  company_naming: t('company_naming'),
  an_company_naming: t('an_company_naming'),
  the_company_naming: t('the_company_naming'),
  of_the_company_naming: t('of_the_company_naming'),
  of_company_naming: t('of_company_naming'),
  of_companys_naming: t('of_companys_naming'),
  this_company_naming: t('this_company_naming'),
  to_the_company_naming: t('to_the_company_naming'),
  company_naming_cap: t('company_naming_cap'),
  an_company_naming_cap: t('an_company_naming_cap'),
  the_company_naming_cap: t('the_company_naming_cap'),
  of_the_company_naming_cap: t('of_the_company_naming_cap'),
  of_company_naming_cap: t('of_company_naming_cap'),
  of_companys_naming_cap: t('of_companys_naming_cap'),
  this_company_naming_cap: t('this_company_naming_cap'),
  to_the_company_naming_cap: t('to_the_company_naming_cap'),
});

export const skillKeysVariables: KeyVariablesType = (t) => ({
  skill_naming: t('skill_naming'),
  an_skill_naming: t('an_skill_naming'),
  the_skill_naming: t('the_skill_naming'),
  of_the_skill_naming: t('of_the_skill_naming'),
  of_skill_naming: t('of_skill_naming'),
  of_skills_naming: t('of_skills_naming'),
  this_skill_naming: t('this_skill_naming'),
  to_the_skill_naming: t('to_the_skill_naming'),
  skill_naming_cap: t('skill_naming_cap'),
  an_skill_naming_cap: t('an_skill_naming_cap'),
  the_skill_naming_cap: t('the_skill_naming_cap'),
  of_the_skill_naming_cap: t('of_the_skill_naming_cap'),
  of_skill_naming_cap: t('of_skill_naming_cap'),
  of_skills_naming_cap: t('of_skills_naming_cap'),
  this_skill_naming_cap: t('this_skill_naming_cap'),
  to_the_skill_naming_cap: t('to_the_skill_naming_cap'),
});

export const applicationKeysVariables: KeyVariablesType = (t) => ({
  application_naming: t('application_naming'),
  an_application_naming: t('an_application_naming'),
  the_application_naming: t('the_application_naming'),
  of_the_application_naming: t('of_the_application_naming'),
  of_application_naming: t('of_application_naming'),
  of_applications_naming: t('of_applications_naming'),
  this_application_naming: t('this_application_naming'),
  to_the_application_naming: t('to_the_application_naming'),
  application_naming_cap: t('application_naming_cap'),
  an_application_naming_cap: t('an_application_naming_cap'),
  the_application_naming_cap: t('the_application_naming_cap'),
  of_the_application_naming_cap: t('of_the_application_naming_cap'),
  of_application_naming_cap: t('of_application_naming_cap'),
  of_applications_naming_cap: t('of_applications_naming_cap'),
  this_application_naming_cap: t('this_application_naming_cap'),
  to_the_application_naming_cap: t('to_the_application_naming_cap'),
});

export const proposalKeysVariables: KeyVariablesType = (t) => ({
  proposal_naming: t('proposal_naming'),
  an_proposal_naming: t('an_proposal_naming'),
  the_proposal_naming: t('the_proposal_naming'),
  of_the_proposal_naming: t('of_the_proposal_naming'),
  of_proposal_naming: t('of_proposal_naming'),
  of_proposals_naming: t('of_proposals_naming'),
  this_proposal_naming: t('this_proposal_naming'),
  to_the_proposal_naming: t('to_the_proposal_naming'),
  proposal_naming_cap: t('proposal_naming_cap'),
  an_proposal_naming_cap: t('an_proposal_naming_cap'),
  the_proposal_naming_cap: t('the_proposal_naming_cap'),
  of_the_proposal_naming_cap: t('of_the_proposal_naming_cap'),
  of_proposal_naming_cap: t('of_proposal_naming_cap'),
  of_proposals_naming_cap: t('of_proposals_naming_cap'),
  this_proposal_naming_cap: t('this_proposal_naming_cap'),
  to_the_proposal_naming_cap: t('to_the_proposal_naming_cap'),
});

export const contextTranslateKeys: contextKeystype = {
  'tags.add.form.label': skillKeysVariables,
  'history.companies.list_invitation.details': missionKeysVariables,
  'history.companies.matching_mission.details': [
    missionKeysVariables,
    clientKeysVariables,
  ],
  'history.companies.mission_invitation.details': [
    missionKeysVariables,
    clientKeysVariables,
  ],
  'history.company.field_updated.market_types.details': [
    missionKeysVariables,
    clientKeysVariables,
  ],
  'history.company.field_updated.skills.details': skillKeysVariables,
  'history.degree.field_created.skills.details': skillKeysVariables,
  'history.mission.field_updated.company_confidentiality.details':
    clientKeysVariables,
  'history.mission.field_updated.optional_skills.details': skillKeysVariables,
  'history.mission.field_updated.vip.details': expertKeysVariables,
  'history.missions.mission_archived_client.title': missionKeysVariables,
  'history.missions.mission_blacklisted.bad_skills.details': [
    missionKeysVariables,
    skillKeysVariables,
  ],
  'history.missions.mission_blacklisted.title': [
    missionKeysVariables,
    expertKeysVariables,
  ],
  'history.missions.mission_created.title': missionKeysVariables,
  'history.missions.mission_invitation_refused.title': expertKeysVariables,
  'history.missions.mission_published.title': missionKeysVariables,
  'history.missions.mission_updated.title': missionKeysVariables,
  'history.users.mission_invitation.title': clientKeysVariables,
  'kicklox_notify.error.candidacy.policy.admin_validate?': expertKeysVariables,
  'resource.candidacies.field.admin_daily_rate.label': clientKeysVariables,
  'resource.candidacies.field.blacklisted.label': missionKeysVariables,
  'resource.candidacies.field.daily_rate.label': expertKeysVariables,
  'resource.candidacies.field.name.label': [
    clientKeysVariables,
    expertKeysVariables,
  ],
  'resource.candidacies.field.request_text.label': [
    clientKeysVariables,
    expertKeysVariables,
  ],
  'resource.candidacies.field.salary.label': expertKeysVariables,
  'resource.candidacies.field.status.choices.step_three.label':
    clientKeysVariables,
  'resource.candidacies.section.blacklisted.title': missionKeysVariables,
  'resource.charges.field.admin_cost_per_unit.label': clientKeysVariables,
  'resource.charges.field.admin_quantity.label': clientKeysVariables,
  'resource.charges.field.cost_per_unit.label': expertKeysVariables,
  'resource.charges.field.quantity.label': expertKeysVariables,
  'resource.companies.field.matching_access.label': missionKeysVariables,
  'resource.companies.field.mission_validation.label': missionKeysVariables,
  'resource.companies.section.client_administration.title': clientKeysVariables,
  'resource.companies.section.skills.title': skillKeysVariables,
  'resource.contract_milestones.field.admin_days_worked.label':
    clientKeysVariables,
  'resource.contract_milestones.field.admin_price.label': clientKeysVariables,
  'resource.contract_milestones.field.client_invoice_id.label':
    clientKeysVariables,
  'resource.contract_milestones.field.expert_days_worked.label':
    expertKeysVariables,
  'resource.contract_milestones.field.expert_invoice_id.label':
    expertKeysVariables,
  'resource.contract_milestones.field.for_client.label': clientKeysVariables,
  'resource.contract_milestones.field.for_expert.label': expertKeysVariables,
  'resource.contract_milestones.field.price.label': expertKeysVariables,
  'resource.contract_milestones.field.status.choices.cancelation_review_client.label':
    clientKeysVariables,
  'resource.contract_milestones.field.status.choices.creation_review_client.label':
    clientKeysVariables,
  'resource.contract_milestones.field.status.choices.delivery_review_client.label':
    clientKeysVariables,
  'resource.contract_milestones.field.status.choices.delivery_with_update_review_client.label':
    clientKeysVariables,
  'resource.contract_milestones.field.status.choices.modification_review_client.label':
    clientKeysVariables,
  'resource.document_files.section.information.title': expertKeysVariables,
  'resource.documents.field.client_condition.choices.mandatory_to_publish_mission.label':
    missionKeysVariables,
  'resource.documents.field.client_condition.label': clientKeysVariables,
  'resource.documents.field.condition.label': expertKeysVariables,
  'resource.documents.field.user_kind.choices.expert.label':
    expertKeysVariables,
  'resource.documents.field.user_kind.choices.mixed.label': [
    clientKeysVariables,
    expertKeysVariables,
  ],
  'resource.invoices.field.latest_client_pdfs.label': clientKeysVariables,
  'resource.invoices.field.latest_expert_pdfs.label': expertKeysVariables,
  'resource.legal_information.field.user_id.label': expertKeysVariables,
  'resource.milestones.field.admin_duty_ratio.label': clientKeysVariables,
  'resource.milestones.field.admin_total_charges.label': clientKeysVariables,
  'resource.milestones.field.admin_total_duty_with_charges.label':
    clientKeysVariables,
  'resource.milestones.field.admin_total_ttc_with_charges.label':
    clientKeysVariables,
  'resource.milestones.field.admin_total_vat_with_charges.label':
    clientKeysVariables,
  'resource.milestones.field.duty_ratio.label': expertKeysVariables,
  'resource.milestones.field.total_charges.label': expertKeysVariables,
  'resource.milestones.field.total_duty_with_charges.label':
    expertKeysVariables,
  'resource.milestones.field.total_ttc_with_charges.label': expertKeysVariables,
  'resource.milestones.field.total_vat_with_charges.label': expertKeysVariables,
  'resource.missions.field.archived_text.label': expertKeysVariables,
  'resource.missions.label': missionKeysVariables,
  'resource.experts.label': expertKeysVariables,
  'resource.clients.label': clientKeysVariables,
  'resource.missions.section.profile.title': missionKeysVariables,
  'resource.missions.sub_menu.label': [
    missionKeysVariables,
    otherKeysVariables,
  ],
  'resource.partner_missions.sub_menu.label': missionKeysVariables,
  'resource.proposals.bulk_action_buttons.view_proposal_as_client.label':
    clientKeysVariables,
  'resource.proposals.field.company_legal_information_id.label':
    expertKeysVariables,
  'resource.proposals.field.status.choices.review_client.label':
    clientKeysVariables,
  'resource.quotations.field.admin_cost_per_day.label': clientKeysVariables,
  'resource.quotations.field.cost_per_day.label': expertKeysVariables,
  'resource.recommendations.field.status.choices.requested.label':
    expertKeysVariables,
  'notify.error.companies.cv_aden_not_activated_for_synchronization':
    expertKeysVariables,
  'notify.error.contract_milestone.policy.admin_validate?': clientKeysVariables,
  'notify.error.contract_milestones.days_worked': [
    expertKeysVariables,
    clientKeysVariables,
  ],
  'notify.error.milestones.for_expert_or_for_client': [
    expertKeysVariables,
    clientKeysVariables,
  ],
  'notify.error.proposal.policy.admin_validate?': clientKeysVariables,
  'notify.error.proposals.validate.empty_admin_cost_per_day':
    clientKeysVariables,
  'notify.error.proposals.validate.empty_admin_vat': clientKeysVariables,
  'notify.error.proposals.validate.empty_milestones_admin_days_worked':
    clientKeysVariables,
  'notify.error.proposals.validate.empty_milestones_admin_units_worked':
    clientKeysVariables,
  'notify.error.proposals.validate.empty_milestones_charges_admin_fields.forfeit':
    clientKeysVariables,
  'notify.error.proposals.validate.empty_milestones_charges_admin_fields.technical_assistance':
    clientKeysVariables,
  'notify.error.proposals.validate.empty_quotation_charges_admin_fields':
    clientKeysVariables,
  'notify.error.proposals.validate.quotation_remaining_admin_duty':
    clientKeysVariables,
  'notify.error.proposals.validate.quotation_remaining_duty':
    expertKeysVariables,
  'notify.error.proposals.validate.empty_admin_cost_per_unit':
    clientKeysVariables,
  'notify.error.resources.contract_milestone.notification.validate.charges_empty_field.technical_assistance':
    clientKeysVariables,
  'notify.error.resources.contract_milestone.notification.validate.charges_empty_field.forfeit':
    clientKeysVariables,
  'notify.error.resources.contract_milestone.notification.validate.empty_field':
    clientKeysVariables,
  'notify.error.resources.users.notification.update_kind_to_expert_failure':
    missionKeysVariables,
  'history.candidacies.candidacy_accepted.title': applicationKeysVariables,
  'history.candidacies.candidacy_archived.title': applicationKeysVariables,
  'history.candidacies.candidacy_created.title': applicationKeysVariables,
  'history.candidacies.candidacy_move_step_four.title':
    applicationKeysVariables,
  'history.candidacies.candidacy_move_step_one.title': applicationKeysVariables,
  'history.candidacies.candidacy_move_step_three.title':
    applicationKeysVariables,
  'history.candidacies.candidacy_move_step_two.title': applicationKeysVariables,
  'history.mission.field_updated.limited_at.details': applicationKeysVariables,
  'history.mission.field_updated.limited.details': applicationKeysVariables,
  'history.missions.mission_candidate.title': applicationKeysVariables,
  'notify.error.candidacies.validate.candidates_empty_admin_daily_rate': [
    applicationKeysVariables,
    clientKeysVariables,
  ],
  'notify.error.candidacies.validate.candidates_empty_daily_rate': [
    applicationKeysVariables,
    expertKeysVariables,
  ],
  'notify.error.candidacies.validate.empty_admin_daily_rate': [
    applicationKeysVariables,
    clientKeysVariables,
  ],
  'notify.error.candidacies.validate.empty_daily_rate': [
    applicationKeysVariables,
    expertKeysVariables,
  ],
  'notify.error.candidacies.validate.empty_daily_rate_and_salary':
    applicationKeysVariables,
  'notify.error.candidacies.validate.empty_hourly_rate':
    applicationKeysVariables,
  'notify.error.candidacies.validate.empty_salary': [
    applicationKeysVariables,
    expertKeysVariables,
  ],
  'notify.error.candidacy.cannot_archive': applicationKeysVariables,
  'notify.error.candidacy.policy.admin_archive?': applicationKeysVariables,
  'notify.error.candidacy.policy.admin_create?': [
    applicationKeysVariables,
    expertKeysVariables,
    missionKeysVariables,
    clientKeysVariables,
  ],
  'notify.error.proposals.validate.candidacy_failure': [
    proposalKeysVariables,
    applicationKeysVariables,
  ],
  'resource.candidacies.field.archived.label': applicationKeysVariables,
  'resource.candidacies.label': applicationKeysVariables,
  'resource.candidacies.section.information.title': applicationKeysVariables,
  'resource.candidacies.section.monitoring.title': applicationKeysVariables,
  'resource.candidacies.tab.information.title': applicationKeysVariables,
  'resource.companies.field.candidacy_validation.label':
    applicationKeysVariables,
  'resource.companies.link.candidacies.label': applicationKeysVariables,
  'resource.contracts.field.candidacy_id.label': applicationKeysVariables,
  'resource.missions.field.limited.label': applicationKeysVariables,
  'resource.proposals.field.candidacy_id.label': applicationKeysVariables,
  'resource.users.bulk_action_buttons.create_a_candidacy.label':
    applicationKeysVariables,
  'history.project.field_created.skills.details': skillKeysVariables,
  'history.certification.field_created.skills.details': skillKeysVariables,
  'history.collaborator.field_created.skills.details': skillKeysVariables,
  'history.experience.field_created.skills.details': skillKeysVariables,
  'history.degree.field_updated.skills.details': skillKeysVariables,
  'history.mission.field_updated.skills.details': skillKeysVariables,
  'history.mission.field_updated.personal_skills.details': skillKeysVariables,
  'history.project.field_updated.skills.details': skillKeysVariables,
  'history.certification.field_updated.skills.details': skillKeysVariables,
  'history.collaborator.field_updated.skills.details': skillKeysVariables,
  'history.experience.field_updated.skills.details': skillKeysVariables,
  'resource.companies.field.skills_name.label': skillKeysVariables,
  'resource.companies.field.personal_skills_name.label': skillKeysVariables,
  'resource.missions.field.mandatory_skills_name.label': skillKeysVariables,
  'resource.missions.field.owner_id.label': clientKeysVariables,
  'resource.invitations.field.recipient_id.label': expertKeysVariables,
  'resource.invitations.field.invitationable_id.label': missionKeysVariables,
  'resource.missions.field.company_id.label': clientKeysVariables,
  'resource.candidacies.field.owner_id.label': expertKeysVariables,
  'resource.candidacies.field.mission_id.label': missionKeysVariables,
  'resource.skills.label': skillKeysVariables,
  'resource.skills.field.childs.label': skillKeysVariables,
  'resource.skills.field.name.label': skillKeysVariables,
  'resource.contract_milestones.field.vat.label': expertKeysVariables,
  'resource.contract_milestones.field.expert_delivery_comment.label':
    expertKeysVariables,
  'resource.contract_milestones.field.expert_delivery_attachments.label':
    expertKeysVariables,
  'resource.contract_milestones.field.client_delivery_comment.label':
    clientKeysVariables,
  'resource.contract_milestones.field.client_delivery_attachments.label':
    clientKeysVariables,
  'resource.quotations.field.cost_per_unit.label': expertKeysVariables,
  'resource.quotations.field.admin_cost_per_unit.label': clientKeysVariables,
  'resource.missions.archive.kind.cancelled_by_client': clientKeysVariables,
  'resource.missions.archive.kind.no_news_from_client': clientKeysVariables,
  'modal.select_company.favorites_entities.delete.title': expertKeysVariables,
  'history.candidacies.candidacy_move_under_review.title':
    applicationKeysVariables,
  'resource.missions.field.generic_offer.label': missionKeysVariables,
  'resource.client_invoices.sub_menu.label': clientKeysVariables,
  'resource.expert_invoices.sub_menu.label': expertKeysVariables,
  'history.missions.mission_republished.title': missionKeysVariables,
  'history.missions.mission_admin_republished.title': missionKeysVariables,
  'history.missions.mission_stand_by.title': missionKeysVariables,
  'history.missions.mission_admin_stand_by.title': missionKeysVariables,
  'notifications.mission_admin_republished.title': missionKeysVariables,
};
