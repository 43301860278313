import React, { FunctionComponent, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import {
  Box as MuiBox,
  Paper as MuiPaper,
  Tab as MuiTab,
  Tabs as MuiTabs,
} from '@material-ui/core';
import { useRecordContext, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import { useHistory } from 'react-router';
import { get } from 'lodash';
import { Tab as TabType } from '../../config/types';
import { Section as SectionType } from '../../config/types';
import { TabPanelProps, TabsProps } from './types';
import checkRules from '../../helpers/rules';

const TabPanel: FunctionComponent<TabPanelProps> = (props) => {
  const { children, value, index, ...restProps } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...restProps}
      // By using z-index instead of display property to display the selected tab content, all the inputs are listened
      // and the logic to handle error is easier
      style={{ zIndex: value === index ? 10 : -1 }}
    >
      <MuiBox m={3}>{children}</MuiBox>
    </div>
  );
};

function a11yProps(index: number | string) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  errorTabs: {
    color: 'red !important',
  },
}));

function Tabs({ tabs, renderItem }: TabsProps): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(() => {
    // Initialize tabs to 0 or to the value from the query string or history state
    const queryString = get(history, 'location.search');
    const params = new URLSearchParams(queryString);
    const currentTabsFromQuery = params.get('currentTabs');

    // Determine the initial value for 'value'
    const initialValue = currentTabsFromQuery
      ? parseInt(currentTabsFromQuery, 10)
      : get(history, 'location.state.currentTabs', 0);

    return initialValue;
  });
  const formState = useFormState();
  const record = useRecordContext();
  const t = useTranslate();

  useEffect(
    () =>
      // Reset currentTabs value in history state when component unmount to avoid tab errors
      () => {
        if (get(history, 'location.state.currentTabs')) {
          const historyState = get(history, 'location.state');
          const { currentTabs, ...newState } = historyState;
          history.replace({ ...location, state: newState });
        }
      },
    []
  );

  // to modify the tabs array in an array easier to use
  const tabsWithInputs = (() =>
    tabs.map((tab: TabType) =>
      tab.sections.reduce((acc: any, section: SectionType) => {
        const { inputs } = section;

        return [...acc, inputs];
      }, [])
    ))();

  /**
   * To check if the content of a tab contains an error
   *
   * @param index
   */
  function hasError(index: number): boolean {
    const errors = formState.errors;
    if (formState && errors && Object.keys(errors).length === 0) return false;

    const errorsInTabs = tabsWithInputs[index].map((t: any) =>
      Object.keys(errors || {}).map((key) => {
        if (t[key]) {
          return true;
        }
      })
    );

    return errorsInTabs.flat().includes(true);
  }

  const checkTabRules = (tab: TabType): boolean => {
    if (tab.rules && tab.rules.hide && checkRules(tab.rules.hide, record)) {
      return true;
    }

    return false;
  };

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <MuiPaper className={classes.root}>
        <MuiTabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          {tabs.map((tab: TabType, index) => {
            if (checkTabRules(tab)) {
              return null;
            }

            return (
              <MuiTab
                key={tab.key}
                wrapped
                label={t(tab.title)}
                {...(hasError(index) && { className: classes.errorTabs })}
                {...a11yProps(index)}
              />
            );
          })}
        </MuiTabs>
      </MuiPaper>
      {tabs.map((tab: TabType, index) => {
        if (checkTabRules(tab)) {
          return null;
        }

        return (
          <TabPanel key={tab.key} value={value} index={index}>
            {renderItem(tab)}
          </TabPanel>
        );
      })}
    </div>
  );
}
export default Tabs;
