import { Config as ConfigType } from './types';
import { user } from './geek/user';
import { expert } from './geek/expert';
import { client } from './geek/client';
import { company } from './geek/company';
import { mission } from './geek/mission';
import { partnerMission } from './geek/partnerMission';
import { invitation } from './geek/invitation';
import { candidate } from './geek/candidate';
import { candidacy } from './geek/candidacy';
import { recommendation } from './geek/recommendation';
import { legalInformation } from './geek/legalInformation';
import { cgu } from './geek/cgu';
import { match } from './geek/match';
import { document } from './geek/document';
import { subCategory } from './geek/subCategory';
import { documentFile } from './geek/documentFile';
import { documentsCompanies } from './geek/documentsCompanies';
import { tag } from './geek/tag';

export const config: ConfigType = {
  login: 'password',
  hash: '4rr0tdosatvzb70almrhxtkbk',
  locale: 'fr',
  name: 'geek',
  dashboard: false,
  theme: {
    primary: '#3D4FF1',
    secondary: '#3D4FF1',
    info: '#f8dddd',
  },
  defaultCountry: 'fr',
  preferredCountries: ['fr', 'ma', 'ch', 'tn'],
  resources: [
    expert,
    client,
    user,
    subCategory,
    company,
    mission,
    partnerMission,
    invitation,
    candidate,
    candidacy,
    recommendation,
    legalInformation,
    document,
    documentFile,
    documentsCompanies,
    cgu,
    match,
    tag,
  ],
};

export default config;
