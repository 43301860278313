import React, { ComponentType, FunctionComponent } from 'react';
import { get } from 'lodash';

/**
 * Add a params currentTabs to an url
 *
 * @param Component
 * @param params
 */
function withTabsParams(
  Component: ComponentType<any>,
  params: Record<string, any> | undefined
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const { record, source, resource } = props;
    const paramsKey = get(params, 'key');
    const paramsValue = get(params, 'value');

    return (
      <Component
        {...props}
        to={`/${resource}/${record[source]}?${paramsKey}=${paramsValue}`}
      />
    );
  };

  Extension.displayName = `withExtension(TabsParams)`;

  return Extension;
}

export default withTabsParams;
